import {Box, Button, ButtonGroup, Input, Modal, Textarea, useDisclosure} from "@lego/klik-ui";
import {ComputerBugBold} from "@lego/klik-ui/icons";
import React, {useState} from "react";
import {Issue} from "../models/Issue";
import {getToken} from "../auth/authConfig";
import {ReportBug} from "../services/ReportBug";
import {useMsal} from "@azure/msal-react";
import {AxiosResponse} from "axios";

export const BugReportModal = () => {
    const { instance } = useMsal();
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const {isOpen, onOpen, onClose} = useDisclosure();
    const initialFocusRef = React.useRef(null);
    const finalFocusRef = React.useRef(null);
    
    const reportBug = (title: string, body: string) => {
        const issue: Issue = {title: title, body: body};
        if (issue.title !== "" || issue.body !== "") {
            getToken(instance).then(token => {
                const gitService = new ReportBug();
                gitService.reportBug(token.accessToken!, issue).then((response: AxiosResponse) => {
                    if (response.status === 200) {
                        onClose();
                        setTitle("");
                        setBody("");
                    }
                });
            });
        }
        console.log(issue);
    }
    return (
          <Box textAlign="right">
        <Button
            className="report-button"
            backgroundColor={"warning.500"}
            onClick={onOpen}
            rightIcon={<ComputerBugBold />}
            sx={{
                '&:hover': {
                    backgroundColor: "warning.600"
                },
            }}
        >
            Report a Bug
        </Button>
        <Modal
            finalFocusRef={finalFocusRef}
            initialFocusRef={initialFocusRef}
            size="4xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <Modal.Overlay/>
            <Modal.Content>
                <Modal.Header>Report A Bug</Modal.Header>
                <Modal.CloseButton />
                <Modal.Body>
                    <Input
                        placeholder="Title"
                        ref={initialFocusRef}
                        value={title}
                        onChange={(e)=> setTitle(e.target.value)}
                        marginBottom={"10px"}/>
                    <Textarea
                        placeholder="Description"
                        value={body}
                        onChange={(e)=> setBody(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup>
                        <Button
                            isDisabled={title === "" || body === ""}
                            onClick={() => reportBug(title, body)}>Send Report</Button>
                        <Button onClick={() => {
                            setTitle("");
                            setBody("");
                            onClose();
                        }
                        }
                                variant="outline">
                            Cancel
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    </Box>
    );
}